<template>
  <div>
    <header-slot>
      <template #actions>
        <b-button
          v-show="isModuleActualEqualTwo"
          v-if="!isOnlyLead"
          variant="success"
          class="mr-1"
          @click="openModalSearch()"
        >
          <feather-icon
            icon="SearchIcon"
            size="15"
            class="mr-50 text-white"
          />Payments Search
        </b-button>
      </template>

    </header-slot>
    <b-nav
      card-header
      pills
      class="m-0"
    >
      <!-- Tab Regular -->
      <b-nav-item
        :to="{ name: 'administration-clients-regular-tab' }"
        :active="isActiveTab(1)"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Regular
      </b-nav-item>

      <!-- Tab Digital -->
      <b-nav-item
        :to="{ name: 'administration-clients-digital-tab' }"
        :active="isActiveTab(2)"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Digital
      </b-nav-item>
    </b-nav>

    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
    <modal-search-payment
      v-if="openModalSearchPayment"
      @close="closeModalSearch"
    />
  </div>
</template>

<script>
import chargeBackService from '@/views/commons/components/charge-back_v2/service/charge.back.service';
import ModalSearchPayment from '@/views/commons/components/charge-back_v2/view/modal/ModalSearchPayment.vue';

export default {
  components: {
    ModalSearchPayment,
  },
  data() {
    return {
      openModalSearchPayment: false,
      isOnlyLead: false,
      moduleActual: null,
      permitStatus: null,
    };
  },
  computed: {
    routerName() {
      return this.$route.matched[2].name;
    },
    isModuleActualEqualTwo() {
      return this.permitStatus === 1;
    },
    // moduleId: function () {
    //   return this.$route.matched[0].meta.module;
    // },
    // ...mapGetters({
    //   currentUser: "auth/currentUser",
    // }),
    // roleId: function () {
    //   return this.currentUser.role_id;
    // },
  },
  async created() {
    // if (this.moduleId !== 6) {
    //   this.getCounter();
    // }
    await Promise.all([
      this.moduleId(),
    ]);
  },
  mounted() {
    this.getAccessModules();
  },
  methods: {
    async getAccessModules() {
      try {
        const params = {
          module_actual: this.moduleActual,
        };
        const result = await chargeBackService.getAccessModule(params);
        this.permitStatus = result[0].permit_status;
      } catch (error) {
        console.error(error);
      }
    },
    moduleId() {
      this.moduleActual = this.$route.matched[0].meta.module;
    },
    openModalSearch() {
      this.openModalSearchPayment = true;
    },
    closeModalSearch() {
      this.openModalSearchPayment = false;
    },
    isActiveTab(tab) {
      if (tab == 1 && this.routerName == 'administration-clients-regular-tab') {
        return true;
      } if (
        tab == 2
        && this.routerName == 'administration-clients-digital-tab'
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style>
.bg-tab {
  background-color: #0090e7;
}
</style>
